import React, { useContext } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { FavContext } from "./FavProvider"
import ProductThumbnailImg from "./ProductThumbnailImg"

const ProductThumbnail = ({ product, loadEarlier }) => {
  const { addfav, remove, hasfav } = useContext(FavContext)
  const isFaved = hasfav(product.id)
  const styleForHeart = isFaved ? "fa fa-heart" : "fa fa-heart-o"

  return (
    <div key={product.id} style={{ breakInside: "avoid" }}>
      <div className="favwrap">
        <div
          className="hoverwrap"
          style={{
            flex: "1 1 auto",
            margin: "0 1rem 3rem",
          }}
        >
          {
            <Link
              to={`/collection/${product.name
                .replace(/\s+/g, "-")
                .toLowerCase()}`}
              style={{ textDecoration: "none" }}
            >
              <ProductThumbnailImg
                product={product}
                loadEarlier={loadEarlier}
              />
            </Link>
          }
          <Link
            to={`/collection/${product.name}`}
            style={{ textDecoration: "none" }}
          >
            <div className="collectionproducttext" text="center">
              {product.name}{" "}
            </div>
          </Link>
          <div className="heartdiv">
            <i
              className={styleForHeart}
              aria-hidden="true"
              style={{ padding: "5px" }}
              onClick={() => {
                if (isFaved) {
                  remove(product.id)
                } else {
                  addfav(product.id)
                }
              }}
            ></i>
          </div>
        </div>
      </div>
    </div>
  )
}

ProductThumbnail.propTypes = {
  product: PropTypes.object.isRequired,
}

export default ProductThumbnail
