import React, { useContext } from "react"
import { ProductsContext } from "./ProductsProvider"
import ProductThumbnail from "./ProductThumbnail"

const CategoryItems = ({ category }) => {
  const { listProducts } = useContext(ProductsContext)
  const products = listProducts().filter((p) => {
    if (category === p.metadata.type) {
      return true
    }
    return false
  })

  return (
    <div className="productslayout">
      {products.map((product, number) => (
        <ProductThumbnail
          key={product.id}
          product={product}
          loadEarlier={number < 3 ? true : false}
        />
      ))}
    </div>
  )
}

export default CategoryItems
