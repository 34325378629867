import React from "react"
import SEO from "../../components/seo"
import { Link } from "gatsby"
import CategoryItems from "../../components/CategoryItems"
import CollectionTemplate from "../../templates/CollectionTemplate"

const RingsPage = () => {
  return (
    <CollectionTemplate
      collectionTitle="RINGS"
      classExtra="ringscollectionpage"
    >
      <SEO
        title="Rings"
        keywords={[`exclusive`, `australian`, `rings`, `engagement`, `wedding`]}
        description={`Custom engagement rings for the unique bride. Bespoke designs using gold and conflict free, ethical, mining free gemstones (diamond, moissanite, morganite, sapphire)`}
      />
      <div className="SEOContainer">
        {/* <div className="PicContain">
          <div className="SEORings" />
        </div> */}
        <div className="SEOText">
          Whatever you're shopping for, be it a show stopping engagement ring,
          everlasting wedding band, magnificent anniversary present, brilliant
          milestone birthday gift, damn-well-deserved push present or just a
          gift to treat yourself, we've got the design of your dreams. All Rowe
          Collection designs are fully customisable, from gemstones to gold
          colour and purity. Want something completely unique? Contact us to
          begin your bespoke journey!
        </div>
      </div>
      <div className="productnavbar">
        <Link className="linkspacer" to="/collection">
          all
        </Link>{" "}
        <div className="linkspacer">/</div>
        <Link className="linkspacer" to="/collection/decollete">
          decollete
        </Link>
        <div className="linkspacer">/</div>
        <Link className="linkspacer" to="/collection/earrings">
          earrings
        </Link>
        <div className="linkspacer">/</div>
        <Link className="linkspacer" to="/collection/homme">
          homme
        </Link>
        <div className="linkspacer">/</div>
        <Link className="linkspacer" to="/collection/bespoke">
          bespoke
        </Link>
        <div className="linkspacer">/</div>
        <Link className="linkspacer" to="/collection/bracelets">
          bracelets
        </Link>
      </div>
      <div id="scroll" className="storewrap">
        <CategoryItems category="rings" />
      </div>
    </CollectionTemplate>
  )
}

export default RingsPage
