import React from "react"
import Layout from "../components/layout"

const CollectionTemplate = ({ classExtra, collectionTitle, children }) => {
  return (
    <Layout classExtra={classExtra}>
      <div className="CollectionWrap">
        <div className="CollectionTitle">{collectionTitle}</div>
      </div>
      {children}
    </Layout>
  )
}

export default CollectionTemplate
