import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const ProductThumbnailImg = ({ product, loadEarlier }) => {
  const images = product.images

  const staticPlacholderData = useStaticQuery(
    graphql`
      query MyQuery {
        file(name: { eq: "missing-image-placeholder-white" }) {
          childImageSharp {
            fluid(maxWidth: 900, quality: 90) {
              src
              srcSet
              sizes
              aspectRatio
            }
          }
        }
      }
    `
  )
  const failOverImage = staticPlacholderData.file.childImageSharp.fluid
  const getPosImg = (pos, failPlaceholder) => {
    const pImg = images.slice(pos, pos + 1)
    if (pImg.length < 1) {
      return failPlaceholder
    } else {
      return pImg.pop()
    }
  }
  const getImg1st = () => getPosImg(0, failOverImage)
  const getImg2nd = () => getPosImg(1, failOverImage)
  const [image, setImage] = useState(getImg1st()) // This should be ok as the logic applies to SS rendered data.

  const handleMouseOverHandler = () => {
    setImage(getImg2nd())
  }

  const handleMouseOutHandler = () => {
    setImage(getImg1st())
  }

  const handleTouchOverHandler = () => {
    setImage(getImg2nd())
  }

  const handleTouchOutHandler = () => {
    setImage(getImg1st())
  }

  return (
    <div>
      <div className="preload">
        <Img fluid={getImg2nd()} loading="eager" />
      </div>
      <div
        onPointerOver={handleMouseOverHandler}
        onPointerLeave={handleMouseOutHandler}
        onTouchStart={handleTouchOverHandler}
        onTouchEnd={handleTouchOutHandler}
      >
        <Img
          fluid={image}
          alt={product.name}
          loading={loadEarlier ? "eager" : "lazy"}
        />
      </div>
    </div>
  )
}

export default ProductThumbnailImg
